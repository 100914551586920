import React, { useEffect, useState } from "react";
import "../styles/recent-messages.scss";
import { GuiSelect } from "../components/global-ui/gui-select";
import { useUpdateState } from "../utilities/utilities";
import AudioPlayer from "../components/audio-player";
import { useOnFirstLoad } from "../utilities/hooks";
import user from "../actions/user-actions";
import ToggleBar from "./ToggleBar";
import CircularProgress from "../actions/CircularProgress";
import actions from "../actions/actions";
import { Path, removeTags } from "../utilities/constants";
import navigate from "../utilities/navigation";

export default function RecentMessages({
  openPreview,
  isEditing,
  isComponentDisabled,
}) {
  const [loading, setLoading] = useState(true);
  const [loaderError, setError] = useState(false);
  let [state, updateState] = useUpdateState(() => {
    return {
      messageType: 0,
      typeOptions: [
        { value: 0, label: "Show All" },
        { value: 1, label: "Voice" },
        { value: 2, label: "Email" },
        { value: 3, label: "SMS" },
      ],
      messages: [],
    };
  });

  const { messageType } = state;
  useOnFirstLoad(() => {
    getMyMessages(messageType);
  });
  useEffect(() => {
    let timeoutId;

    if (loading) {
      // Set a timeout for 15 seconds
      timeoutId = setTimeout(() => {
        setLoading(false);
        setError(true);
      }, 15000);
    }

    // Cleanup the timeout on component unmount or when loading changes
    return () => clearTimeout(timeoutId);
  }, [loading]);
  async function getMyMessages(messageType) {
    setError(false);
    try {
      let result = await user.getRecentMessages(messageType);

      if (result.success) {
        // Use Promise.all to handle asynchronous map
        const updatedMessages = await Promise.all(
          result.recentMessages.map(async (item) => {
            if (item.messageType === "Voice") {
              const response = await convertTTS(item.messageDescription);

              if (response.success) {
                // Add audioUrl to the item for voice message type : need to check if voice is TTs or not ..
                return { ...item, audioUrl: response.fileUrl };
              }
            }
            // If not "Voice" => return the item unchanged
            return item;
          })
        );

        updateState({ messages: updatedMessages });
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }

  async function convertTTS(description) {
    const result = await actions.alerts.textToSpeech(description);
    return result;
  }

  const onMessageTypeChange = (name, value) => {
    setLoading(true);
    updateState({ [name]: value });
    getMyMessages(value);
  };
  const formatDescription = (string) => {
    return string.split(" ").slice(0, 15).join(" ");
  };
  const formattedDate = (orgDate) => {
    const originalDate = new Date(orgDate);

    const yesterday = () => {
      let d = new Date();
      d.setDate(d.getDate() - 1);
      return d;
    };
    const currentDate = new Date().toISOString().split("T")[0];
    const yesterdayDate = yesterday().toISOString().split("T")[0];
    const scheduledDate = orgDate.split("T")[0];
    const month = originalDate.getMonth() + 1;
    const date = originalDate.getDate();
    const year = originalDate.getFullYear();

    const formattedTime = originalDate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    if (scheduledDate === currentDate) {
      return `${formattedTime}`;
    } else if (scheduledDate === yesterdayDate) {
      return "Yesterday";
    } else {
      return `${month}/${date}/${year}`;
    }
  };

  const handleTabToggle = (tabState) => {
    console.log("Active Tab : ", tabState);
    // This tab functionality will be implemented when we implement message preview .
  };
  const onMsgClick = (msg) => {
    const newMsg = { ...msg, dateTime: formattedDate(msg.dateTimeStamp) };
    openPreview(newMsg);
  };
  return (
    <>
      <div
        className={`messages-container ${loaderError && "loader-error"} ${
          (isEditing || isComponentDisabled) && "disabled"
        }`}
      >
        {loading && <CircularProgress />}
        {!loading && loaderError && (
          <span>Failed to load data, please try again later.</span>
        )}
        {!loading && !loaderError && (
          <>
            <div className="header">
              <span className="header-title">Recent Messages</span>
              <div className="filters">
                <ToggleBar onTabClick={handleTabToggle}></ToggleBar>
                <GuiSelect
                  className="messageType"
                  label="Message Type"
                  name="messageType"
                  value={state.messageType}
                  onChange={(name, option) =>
                    onMessageTypeChange(name, option.value)
                  }
                  options={state.typeOptions}
                />
              </div>
            </div>

            <div className="messages-list">
              {state.messages.map((msg, index) => (
                <div
                  key={index}
                  className="message-item"
                  onClick={() => onMsgClick(msg)}
                >
                  <div className="avatar">
                    {msg.avatar ? (
                      <img src={msg.avatar} alt={`${msg.senderName}`} />
                    ) : (
                      <div className="initials">
                        {msg.senderName
                          .split(" ")
                          .map((n) => n[0])
                          .slice(0, 2)
                          .join("")}
                      </div>
                    )}
                  </div>
                  <div className="message-content">
                    <div className="message-header">
                      <span className="message-header-name">
                        {msg.senderName}
                      </span>
                      {msg.messageType === "Voice" ? (
                        <div className="voice-note">
                          <div className="message-preview">
                            <div className="message-preview-subject">
                              {msg.messageType} – {msg.subject}
                            </div>

                            <div className="message-preview-time">
                              {formattedDate(msg.dateTimeStamp)}
                            </div>
                          </div>
                          <AudioPlayer src={msg.audioUrl} autoPlay={false} />
                        </div>
                      ) : (
                        <>
                          <div className="message-preview">
                            <div className="message-preview-subject">
                              {msg.messageType} – {msg.subject}
                            </div>

                            <div className="message-preview-time">
                              {formattedDate(msg.dateTimeStamp)}
                            </div>
                          </div>
                          <span className="message-preview-content">
                            {removeTags(
                              formatDescription(msg.messageDescription)
                            )}
                            {" ..."}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="recent-message-divider"></div>
            <div
              className="go-to-message-btn"
              onClick={() => navigate(Path.Track)}
            >
              Go to Messages
              <i className=" go-to-message-icon far fa-chevron-right"></i>
            </div>
          </>
        )}
      </div>
    </>
  );
}
