import api from "../api/api";
import { groups } from "../api/groups-api";
import { appState, dispatch } from "../redux/redux-config";
import { storage } from "../utilities/storage";

const group = {
  initPage: function () {
    let state = appState();
    let token = state.user.token;

    // Load filters
    if (!state.locationOptions) {
      api.groups.getLocations(token).then((result) => {
        if (result.success)
          dispatch("GROUP_SETTINGS_UPDATE", {
            locationOptions: result.locations.map((g) => ({
              value: g.id,
              label: g.name,
            })),
          });
      });
    }

    if (!state.groupSettingsForUser) {
      api.groups.getGroupDetails(token, 0).then((result) => {
        if (result.success) {
          result.settings.groupTypes = result.settings.groupTypes.map((x) => ({
            value: x.id,
            label: x.name,
          }));
          dispatch("GROUP_SETTINGS_UPDATE", {
            groupSettingsForUser: result.settings,
            newGroupTemplate: result.group,
          });
        }
      });
    }

    // Load column visibility
    if (!state.gridColumns) {
      let gridColumns = [
        { name: "Group Name", column: "groupname", show: true },
        { name: "Location", column: "location", show: true },
        { name: "Type", column: "grouptype", show: true },
        { name: "Alerts", column: "alerts", show: true },
        { name: "Group ID", column: "groupid", show: false },
      ];
      let saved = storage.getObject("groups-columns");
      if (saved)
        gridColumns.forEach((c) => (c.show = saved.includes(c.column)));

      dispatch("GROUP_SETTINGS_UPDATE", { gridColumns: gridColumns });
    }
  },

  load: async (search, page, pageSize, skipTotal) => {
    let token = appState().user.token;
    let result = await api.groups.list(
      token,
      search,
      page,
      pageSize,
      skipTotal
    );

    if (result.success) {
      dispatch({
        type: "GROUPS_lOADED",
        payload: {
          list: result.groups,
          totalCount: result.totalCount,
        },
      });
      return {
        list: result.groups,
        totalCount: result.totalCount,
      };
    }
  },

  getGroupGrid: async function (
    search,
    locations,
    groupTypes,
    sort,
    page,
    pageSize,
    skipTotal
  ) {
    let token = appState().user.token;
    let result = await api.groups.getGroupGrid(
      token,
      search,
      locations,
      groupTypes,
      sort,
      page,
      pageSize,
      skipTotal
    );
    if (result.success) return result;
  },

  handleMakeLeader: async function (userId) {
    let token = appState().user.token;
    let result = await api.groups.handleMakeLeader(token, userId);
    if (result.success) return result;
  },

  removePeopleFromGroup: async function (groupId, userId) {
    let token = appState().user.token;
    let result = await api.groups.deleteUserFromGroup(token, groupId, userId);
    if (result.success) return result;
  },

  loadGroupOptions: function () {
    let state = appState();
    if (state.groups.groupOptions) return state.groups.groupOptions;

    if (!state.groups.sync.loadingGroupOptions) {
      state.groups.sync.loadingGroupOptions = true;
      let token = state.user.token;
      api.groups.getGroupOptions(token).then((result) => {
        if (result.success) {
          state.groups.sync.loadingGroupOptions = false;
          dispatch("GROUP_SETTINGS_UPDATE", {
            groupOptions: result.list.map((g) => ({
              value: g.id,
              label: g.name,
            })),
          });
        }
      });
    }
    return null;
  },

  loadGroupTypes: function () {
    let state = appState();
    if (state.groups.groupTypeOptions) return state.groups.groupTypeOptions;

    if (!state.groups.sync.loadingGroupTypeOptions) {
      state.groups.sync.loadingGroupTypeOptions = true;
      let token = state.user.token;
      api.groups.getGroupTypes(token).then((result) => {
        if (result.success) {
          state.groups.sync.loadingGroupTypeOptions = false;
          dispatch("GROUP_SETTINGS_UPDATE", {
            groupTypeOptions: result.list.map((g) => ({
              value: g.id,
              label: g.name,
            })),
          });
        }
      });
    }
    return null;
  },

  getGroupDetails: async function (id) {
    let token = appState().user.token;
    let result = await api.groups.getGroupDetails(token, id || 0);
    if (result.success) return result;
  },

  deleteUsersFromGroup: async function (groupId, personIds) {
    let token = appState().user.token;
    let result = await api.groups.deleteUsersFromGroup(
      token,
      groupId,
      personIds
    );
    if (result.success) return result;
  },

  getAllGroupMewmbers: async function (groupId) {
    try {
      let token = appState().user.token;
      let result = await api.groups.getAllGroupMewmbers(token, groupId);

      if (result.success) {
        return result;
      }
    } catch (error) {
      console.error("Error getting group members:", error);
    }
  },

  save: async function (group) {
    let token = appState().user.token;
    let result = await api.groups.save(token, group);
    if (result.success) return result;
  },

  merge: async function (mergeIds, destinationGroupId, newGroup) {
    let token = appState().user.token;
    let data = {
      GroupIds: mergeIds,
      DestinationGroupId: destinationGroupId,
      NewGroup: newGroup,
    };

    return await api.groups.merge(token, data);
  },

  deleteGroups: async function (groupIds) {
    let token = appState().user.token;
    return await api.groups.deleteGroups(token, groupIds);
  },
};
export default group;

export function groupsReducer(state, action) {
  if (state === undefined) return initialState;

  switch (action.type) {
    case "GROUP_SETTINGS_UPDATE":
      return {
        ...state,
        ...action.payload,
      };

    case "GROUP_LIST_CHANGED":
      return {
        ...state,
        groupOptions: null,
      };

    case "GROUPS_LOADED":
      return {
        ...state,
        groups: action.payload.list,
        totalCount: action.payload.totalCount,
      };

    //case '@@router/LOCATION_CHANGE':
    //    let path = action.payload.location.pathname;
    //    if (path === Path.People || path === Path.PeopleAdmin || path === Path.PeopleManagers) {
    //        return {
    //            ...state,
    //            tab: path
    //        }
    //    }
    //    return state;

    case "LOGOUT":
      return initialState;

    default:
      return state;
  }
}
const initialState = {
  sync: {},
  groups: [],
  totalCount: 0,
};
