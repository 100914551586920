import { appState } from "../redux/redux-config";
import { apiRequestWithChecks } from "./api";

export const messages = {
  list: function (payload) {
    let token = appState().user.token;
    return apiRequestWithChecks(
      "POST",
      "/api/message/MessageList",
      {
        Authorization: "Bearer " + token,
      },
      payload
    );
  },
  bulkDelete: function (payload) {
    let token = appState().user.token;
    return apiRequestWithChecks(
      "DELETE",
      "/api/message/bulk-delete",
      {
        Authorization: "Bearer " + token,
      },
      payload
    );
  },
};
export default messages;
