import React, { useEffect } from "react";
import { GuiButton } from "../components/global-ui/gui-button";
import { Icon } from "../components/icon";
import PageLayout from "../components/page-layout";
import { useSelector } from "react-redux";
import Grid, { ColumnHeader } from "../components/grid";
import Card from "../components/card";
import {
  CheckBoxHeaderButton,
  GuiCheckbox,
} from "../components/global-ui/gui-checkbox";
import {
  downloadFile,
  pluralize,
  useUpdateState,
} from "../utilities/utilities";
import { GridCell } from "../components/grid";
import Popover from "../components/popover";
import "../styles/global-ui/group-members.scss";
import { SearchBox } from "../components/search-box";
import { FilterDropdown } from "../components/filter-dropdown";
import actions from "../actions/actions";
import { EditMultiple } from "../components/edit-multiple";
import { ColumnPicker } from "../components/column-picker";
import { FilterTagBar } from "../components/filter-tag-bar";
import { storage } from "../utilities/storage";
import { dispatch } from "../redux/redux-config";
import { useAddUserToGroup } from "../components/global-ui/add-user-to-group";
import { Modal } from "../components/modal";
import { GuiSelect } from "../components/global-ui/gui-select";
import { useState } from "react";

export default function GroupMembers({ match, location, history }) {
  let [state, updateState] = useUpdateState(() => {
    return {
      editMultipleItems: [
        { name: "Add Users to Group" },
        { name: "Delete Users from System" },
        /*{ name: "Send Alert to Users" },*/
        { name: "Make High Priority" },
        { name: "Remove High Priority" },
        { name: "Export" },
      ],
      search: "",
      filters: [],
      sort: {},
      gridReset: 0,
      currentPage: [],
      showActionsMenu: null,
      defaultGridColumns: [],
    };
  });

  let [checkboxState, updateCheckboxState, setCheckboxState] = useUpdateState(
    {}
  );
  let { locationOptions, gridColumns, groupSettingsForUser } = useSelector(
    (store) => store.groups
  );
  useEffect(() => {
    actions.groups.initPage();
    if (!gridColumns) {
      const defaultGridColumns = [
        { name: "Group Name", column: "groupname", show: true },
        { name: "Location", column: "location", show: true },
        { name: "Type", column: "grouptype", show: true },
        { name: "Alerts", column: "alerts", show: true },
        { name: "Group ID", column: "groupid", show: false },
      ];
      updateState({ defaultGridColumns: defaultGridColumns });
    }
  }, []);

  const {
    showAddToGroupModal,
    setShowAddToGroupModal,
    addToGroupId,
    individual,
    currentGroup,
    saving,
    openAddUserToGroupModal,
    setAddToGroupId,
  } = useAddUserToGroup(checkboxState);

  let groupTypeOptions =
    useSelector((store) => actions.groups.loadGroupTypes()) || [];
  let groupId = match.params.id;
  let { groupDetails, groupName } = location.state;
  const [groupDetailsState, setGroupDetailsState] = useState(groupDetails);

  let isMobile = useSelector((store) => store.page.isMobile);
  let locationName = groupSettingsForUser
    ? groupSettingsForUser.locationName || "Location"
    : "";
  let userTypeOptions =
    useSelector((store) => actions.user.loadUserTypes()) || [];

  let selectedRows = Object.keys(checkboxState).filter((k) => checkboxState[k]);

  let groupOptions =
    useSelector((store) => actions.groups.loadGroupOptions()) || [];
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [activePopover, setActivePopover] = useState(null);
  const [showModal, setShowModal] = useState({ show: false, userId: null });
  const [showRemoveModal, setShowRemoveModal] = useState({
    show: false,
    userId: null,
  });

  React.useEffect(() => {}, [groupDetailsState]);

  function toggleAllCheckboxes() {
    let any = Object.keys(checkboxState).find((k) => checkboxState[k]);
    if (any || !state.currentPage) setCheckboxState({});
    else
      setCheckboxState(
        state.currentPage.reduce((newState, row) => {
          newState[row.id] = true;
          return newState;
        }, {})
      );
  }

  let { sort } = state;

  let filterOptions = [
    // { name: "User Type", options: userTypeOptions },
    // { name: "Group", options: groupOptions },
    { name: "Criteria", options: state.criteriaOptions },
    // { name: locationName, options: locationOptions || [] },
    { name: "Group Type", options: groupTypeOptions || [] },
  ];

  function onSort(column) {
    let currentDirection = state.sort[column];
    let nextDirection =
      currentDirection === true
        ? false
        : currentDirection === false
        ? undefined
        : true;
    updateState({
      sort: { ...state.sort, [column]: nextDirection },
      gridReset: state.gridReset + 1,
    });
  }

  function setSearch(search) {
    updateState({ search: search, gridReset: state.gridReset + 1 });
  }

  function onFilterChange(action, values) {
    if (action === "clear")
      updateState({ filters: [], gridReset: state.gridReset + 1 });
    else {
      let newFilters = state.filters.concat(values);
      updateState({ filters: newFilters, gridReset: state.gridReset + 1 });
    }
  }

  async function onEditMultipleClick(item) {
    if (item.name === "Add Users to Group")
      updateState({ showAddToGroupModal: true, individual: null });
    else if (item.name === "Delete Users from System")
      updateState({ showDeleteUsersModal: true, individual: null });
    else if (item.name === "Make High Priority")
      updateState({ showMakeHighPriority: true, individual: null });
    else if (item.name === "Remove High Priority")
      updateState({ showRemoveHighPriority: true, individual: null });
    else if (item.name === "Export") {
      let token = await actions.user.getShortTermToken();
      if (token) {
        downloadFile(
          "/api/people/export?personIds=" +
            Object.keys(checkboxState)
              .filter((k) => checkboxState[k])
              .join(",") +
            "&token=" +
            encodeURIComponent(token)
        );
      }
    }
  }

  async function addUsersToGroup() {
    let userIds = state.individual
      ? [state.individual.id]
      : Object.keys(checkboxState).filter((k) => checkboxState[k]);
    updateState({ saving: true });
    await actions.people.addPeopleToGroup(state.addToGroupId, userIds);
    updateState({
      saving: false,
      showAddToGroupModal: false,
      addToGroupId: null,
      individual: null,
    });
  }

  async function deleteUsers() {
    let userIds = state.individual
      ? [state.individual.id]
      : Object.keys(checkboxState).filter((k) => checkboxState[k]);
    updateState({ saving: true });
    await actions.people.deletePeople(userIds);
    updateState({
      saving: false,
      showDeleteUsersModal: false,
      individual: null,
      gridReset: state.gridReset + 1,
    });
  }

  async function setUserPriority(setHigh) {
    let userIds = state.individual
      ? [state.individual.id]
      : Object.keys(checkboxState).filter((k) => checkboxState[k]);
    updateState({ saving: true });
    await actions.people.setPriority(userIds, setHigh);
    updateState({
      saving: false,
      showMakeHighPriority: false,
      showRemoveHighPriority: false,
      individual: null,
      gridReset: state.gridReset + 1,
    });
  }

  function onColumnChange(item, show) {
    let cols = [...gridColumns];
    let i = cols.findIndex((c) => c.name === item.name);
    cols[i] = { ...cols[i], show: show };

    let list = cols.filter((c) => c.show).map((c) => c.column);
    storage.setObject("groups-columns", list);

    dispatch("GROUP_SETTINGS_UPDATE", { gridColumns: cols });
  }

  function removeFilter(filter) {
    updateState({
      filters: state.filters.filter((f) => f !== filter),
      gridReset: state.gridReset + 1,
    });
  }

  function getUsersText() {
    return individual
      ? individual.firstName + " " + individual.lastName
      : "these users";
  }

  async function onItemsNeeded(page, pageSize, skipTotal) {
    //let groupDetails = location.state.groupDetails;
    let groupDetails = groupDetailsState;

    if (Array.isArray(groupDetails.members)) {
      let items = groupDetails.members;

      // Filter items based on search state
      if (state.search) {
        items = items.filter((item) => {
          return (
            item.firstName.toLowerCase().includes(state.search.toLowerCase()) ||
            item.lastName.toLowerCase().includes(state.search.toLowerCase())
          );
        });
      }

      items = items.slice((page - 1) * pageSize, page * pageSize);

      return {
        list: items,
        totalCount: groupDetails.members.length,
      };
    } else {
      return {
        list: [],
        totalCount: 0,
      };
    }
  }

  function getUserType(userType) {
    switch (Number(userType)) {
      case 1:
        return "Student";
      case 2:
        return "";
      case 3:
        return "Faculty";
      case 4:
        return "Admin";
      default:
        return "";
    }
  }

  function handleCheckboxChange(userId, checked) {
    updateCheckboxState(userId, checked);

    if (checked) {
      setSelectedUsers((prevUsers) => [...prevUsers, userId]);
    } else {
      setSelectedUsers((prevUsers) => prevUsers.filter((id) => id !== userId));
    }
  }

  const handleIconClick = (userId) => {
    setActivePopover(activePopover === userId ? null : userId);
  };

  const handlePopoverHide = (id) => {
    setShowPopover(id);
  };

  async function handleMakeLeader(userId) {
    updateState({ saving: true });
    await actions.groups.handleMakeLeader(userId);
    setShowModal({ show: false, userId: null });
    updateState({ saving: false });
    handlePopoverHide(null);
  }

  async function handleRemove(userId, groupId) {
    try {
      updateState({ ...state, saving: true });
      await actions.groups.removePeopleFromGroup(groupId, userId);
      setShowRemoveModal({ show: false, userId: null });
      updateState({ ...state, saving: false });
      handlePopoverHide(null);

      // Fetch the updated group details
      let details = await actions.groups.getAllGroupMewmbers(groupId);

      // Check if the removed user is still in the details.members array
      if (details.members.find((member) => member.userId === userId)) {
        // The removed user is still in the fetched group details
      }

      // Update the state with the new group details

      setGroupDetailsState(details);
    } catch (error) {
      console.error("Error in handleRemove:", error);
    }
    // window.location.reload();
  }

  return (
    <PageLayout
      title={groupName}
      className="group-members"
      buttonBar={
        <>
          <GuiButton
            onClick={() => {
              let groupIds = groupId ? [groupId] : selectedRows;
              let groups = state.currentPage.filter((g) =>
                groupIds.includes(g.userId)
              );
              actions.alerts.showCreateAlert(false, groups);
            }}
            className="create-group-button"
          >
            Message Group
          </GuiButton>
        </>
      }
      searching={false}
    >
      <Card className="group-members-card">
        {/* Desktop Search bar */}
        {!isMobile && (
          <div className="page-layout-content-header">
            <SearchBox onSearchChange={setSearch} searching={state.loading} />
            <FilterDropdown
              filters={filterOptions}
              onFilterChange={onFilterChange}
            />

            <div className="tools">
              <EditMultiple
                items={state.editMultipleItems}
                onItemClick={onEditMultipleClick}
                count={selectedRows.length}
              />
              <ColumnPicker
                items={gridColumns ? gridColumns : state.defaultGridColumns}
                onItemChange={onColumnChange}
              />
            </div>

            <FilterTagBar filters={state.filters} onRemove={removeFilter} />
          </div>
        )}

        {/* Mobile filter bar */}
        {isMobile && (
          <div className="mobile-filter-bar">
            <FilterDropdown
              filters={filterOptions}
              onFilterChange={onFilterChange}
            />
          </div>
        )}
        <Grid
          // groupDetails={groupDetails}
          groupDetails={groupDetailsState}
          header={
            <div className="header-row row">
              <ColumnHeader className="check">
                <CheckBoxHeaderButton
                  checked={Object.keys(checkboxState).find(
                    (k) => checkboxState[k]
                  )}
                  onClick={toggleAllCheckboxes}
                />
              </ColumnHeader>
              <ColumnHeader className="groupname" sort={sort} onSort={onSort}>
                First Name
              </ColumnHeader>
              <ColumnHeader className="location" sort={sort} onSort={onSort}>
                Last Name
              </ColumnHeader>
              <ColumnHeader className="grouptype" sort={sort} onSort={onSort}>
                User Name
              </ColumnHeader>
              <ColumnHeader className="groupid" sort={sort} onSort={onSort}>
                User Type
              </ColumnHeader>
            </div>
          }
          itemTemplate={(g) => {
            return (
              <div className="row" key={g.userId}>
                <GridCell className="check">
                  <GuiCheckbox
                    name={"" + g.userId}
                    value={checkboxState[g.userId]}
                    onChange={(name, checked) =>
                      handleCheckboxChange(name, checked)
                    }
                  />
                </GridCell>
                <GridCell className="grid-initials">
                  <span className="initials">
                    {(g.firstName || "").substr(0, 2)}
                  </span>
                </GridCell>
                <GridCell className="location">{g.firstName || ""}</GridCell>
                <GridCell className="grouptype">{g.lastName || ""}</GridCell>
                <GridCell className="groupid">{g.userName || ""}</GridCell>
                <GridCell className="alerts">
                  {getUserType(g.userType) || ""}
                </GridCell>

                <GridCell className="actions">
                  <Icon
                    icon="ellipsis-h"
                    weight="400"
                    onClick={() => {
                      handlePopoverHide(g.userId);
                    }}
                  />
                  <Popover
                    show={
                      showPopover === g.userId &&
                      !showModal.show &&
                      !showRemoveModal.show
                    }
                    onHide={() => {
                      handlePopoverHide(null);
                    }}
                    className="make-leader-div"
                  >
                    <div
                      className="menu-item"
                      onClick={() =>
                        setShowModal({ show: true, userId: g.userId })
                      }
                    >
                      Make Leader
                    </div>
                    <div
                      className="menu-item"
                      onClick={() =>
                        setShowRemoveModal({ show: true, userId: g.userId })
                      }
                    >
                      Remove
                    </div>
                  </Popover>
                </GridCell>
              </div>
            );
          }}
          onItemsNeeded={onItemsNeeded}
          search={state.search}
          gridReset={state.gridReset}
        />
      </Card>
      {/* Add to Group */}
      {state.showAddToGroupModal && (
        <Modal className="plain-modal">
          <p>Select a group you would like to add {getUsersText()} to.</p>
          <GuiSelect
            label="Group"
            name="addToGroupId"
            options={groupOptions}
            value={state.addToGroupId}
            onChange={(name, option) => updateState(name, option.value)}
          />
          <div className="buttons">
            <GuiButton
              className="btn-outline"
              onClick={(e) =>
                updateState({
                  showAddToGroupModal: false,
                  addToGroupId: null,
                  individual: null,
                })
              }
            >
              Cancel
            </GuiButton>
            <GuiButton
              onClick={addUsersToGroup}
              disabled={!state.addToGroupId}
              loading={state.saving}
            >
              Add {state.individual ? "" : Object.keys(checkboxState).length}{" "}
              User
              {state.individual || Object.keys(checkboxState).length === 1
                ? ""
                : "s"}
            </GuiButton>
          </div>
        </Modal>
      )}

      {/* Delete */}
      {state.showDeleteUsersModal && (
        <Modal className="plain-modal">
          <p>
            Are you sure you want to permanently remove {getUsersText()} from
            the system?
          </p>
          <div className="buttons">
            <GuiButton
              className="btn-outline"
              onClick={(e) =>
                updateState({ showDeleteUsersModal: false, individual: null })
              }
            >
              Cancel
            </GuiButton>
            <GuiButton
              className="warning"
              onClick={deleteUsers}
              loading={state.saving}
            >
              Delete {state.individual ? "" : Object.keys(checkboxState).length}{" "}
              User
              {state.individual || Object.keys(checkboxState).length === 1
                ? ""
                : "s"}
            </GuiButton>
          </div>
        </Modal>
      )}

      {/* High Priority */}
      {state.showMakeHighPriority && (
        <Modal className="plain-modal">
          <p>Would you like to make {getUsersText()} high priority?</p>
          <div className="buttons">
            <GuiButton
              className="btn-outline"
              onClick={(e) =>
                updateState({ showMakeHighPriority: false, individual: null })
              }
            >
              Cancel
            </GuiButton>
            <GuiButton
              onClick={(e) => setUserPriority(true)}
              loading={state.saving}
            >
              Make High Priority
            </GuiButton>
          </div>
        </Modal>
      )}

      {/* Low Priority */}
      {state.showRemoveHighPriority && (
        <Modal className="plain-modal">
          <p>
            Are you sure you want to remove high priority from {getUsersText()}?
          </p>
          <div className="buttons">
            <GuiButton
              className="btn-outline"
              onClick={(e) =>
                updateState({ showRemoveHighPriority: false, individual: null })
              }
            >
              Cancel
            </GuiButton>
            <GuiButton
              onClick={(e) => setUserPriority(false)}
              loading={state.saving}
            >
              Remove High Priority
            </GuiButton>
          </div>
        </Modal>
      )}
      {/* Make Leader */}
      {showModal.show && (
        <Modal>
          <p>Are you sure you want to make user Leader?</p>
          <GuiButton
            className="btn-outline"
            onClick={() => {
              setShowModal({ show: false, userId: null });
              handlePopoverHide(null);
            }}
          >
            Cancel
          </GuiButton>
          <GuiButton
            onClick={() => handleMakeLeader(showModal.userId)}
            loading={state.saving}
          >
            Make Leader
          </GuiButton>
        </Modal>
      )}
      {/* Remove */}
      {showRemoveModal.show && (
        <Modal>
          <p>Are you sure you want to remove user from group?</p>
          <GuiButton
            className="btn-outline"
            onClick={() => {
              setShowRemoveModal({ show: false, userId: null });
              handlePopoverHide(null);
            }}
          >
            Cancel
          </GuiButton>
          <GuiButton
            onClick={() => handleRemove(showRemoveModal.userId, groupId)}
            loading={state.saving}
          >
            Remove
          </GuiButton>
        </Modal>
      )}
    </PageLayout>
  );
}
