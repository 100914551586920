import React, { Fragment, useEffect, useState } from "react";
import actions from "../../actions/actions";
import { useUpdateState } from "../../utilities/utilities";
import Card from "../../components/card";
import MessageDropdown from "../MessageDropdown";
import { Tooltip } from "react-bootstrap";
import PageLayout from "../../components/page-layout";
import user from "../../actions/user-actions";
import "../../styles/messages.scss";
import Grid from "../../components/grid";
import { GuiCheckbox } from "../../components/global-ui/gui-checkbox";
import { GuiButton } from "../../components/global-ui/gui-button";
import AudioPlayer from "../../components/audio-player";
import CircularProgress from "../../actions/CircularProgress";
import MessageTabs from "./MessageTabs";
import { Modal } from "../../components/modal";
import api from "../../api/api";
import { showToast } from "../../components/toast";
import { SliderSearchBox } from "../../components/slider-search-box";
import { useSelector } from "react-redux";
import BottomNavBar from "../BottomNavBar";
import { removeTags, useIsDesktop } from "../../utilities/constants";

export default function Messages() {
  let [state, updateState] = useUpdateState(() => {
    return {
      messageList: [],
      totalCount: 0,
      scheduledCount: 0,
      totalCredits: "",
      remainingCredits: "",
      billingCycle: "Monthly",
      gridReset: 0,
      isDeleteEnabled: false,
      showDeleteModal: false,
      isLoading: false,
      selectedMsg: 0,
      alertType: [
        { value: 1, label: "Voice" },
        { value: 2, label: "Email" },
        { value: 3, label: "SMS" },
      ],
    };
  });
  const [isOpen, setIsOpen] = useState(false);
  const [audioUrls, setAudioUrls] = useState({});
  let [checkboxState, updateCheckboxState, setCheckboxState] = useUpdateState(
    {}
  );
  const [selectedTab, setSelectedTab] = useState("all");
  let isMobile = useSelector((store) => store.page.isMobile);
  const tabs = [
    { id: "all", label: "All" },
    { id: "sent", label: "Sent" },
    { id: "received", label: "Received" },
    { id: "scheduled", label: "Scheduled" },
    { id: "drafts", label: "Drafts" },
  ];

  const getSelectedCheckboxes = () => {
    const selectedCheckboxes = Object.entries(checkboxState)
      .filter(([key, value]) => value)
      .map(([key]) => {
        let [groupId, alertId] = key.split("_");
        const message = state.messageList.find((msg) => {
          return (
            msg.id === parseInt(alertId) && msg.groupId === parseInt(groupId)
          );
        });

        return message;
      });
    const filteredCheckboxes = selectedCheckboxes
      .filter((item) => item.status === 3 || item.status === 2)
      .map((items) =>
        (({ id, groupId }) => ({
          alertId: id,
          groupId: groupId,
        }))(items)
      );
    filteredCheckboxes.length > 0
      ? updateState({
          isDeleteEnabled: true,
          filteredCheckboxes: filteredCheckboxes,
          selectedMsg: filteredCheckboxes.length,
        })
      : updateState({
          isDeleteEnabled: false,
          selectedMsg: 0,
        });

    return filteredCheckboxes;
  };
  const handleTabChange = (tabId) => {
    setSelectedTab(tabId);
    updateState({ gridReset: state.gridReset + 1 });
  };
  useEffect(() => {
    getSelectedCheckboxes();
  }, [checkboxState]);

  useEffect(() => {
    getAlertCredit();
    if (isOpen) {
      const tooltip = document.querySelector(".gui-tooltip");
      const icon = document.querySelector(".mobile-title-progress-icon");
      const iconRect = icon.getBoundingClientRect();
      tooltip.style.top = `${iconRect.bottom}px`;
      tooltip.style.left = `${
        iconRect.left + iconRect.width / 2 - tooltip.offsetWidth / 2
      }px`;
    }
  }, [isOpen]);

  async function getAlertCredit() {
    let result = await user.getAlertCredit();
    if (result.success) {
      updateState({
        totalCredits: result.totalCredits,
        remainingCredits: result.remainingCredits,
        billingCycle: result.billingCycle,
      });
      return result;
    }
  }
  async function convertTTS(description) {
    const result = await actions.alerts.textToSpeech(description);
    return result;
  }
  async function loadRows(page, pageSize, skipTotal, tabId = selectedTab) {
    updateState({ loading: true });

    let payload = {
      status:
        tabId === "all"
          ? 0
          : tabId === "sent"
          ? 1
          : tabId === "received"
          ? 4
          : tabId === "scheduled"
          ? 2
          : 3,
      pageNum: page,
      pageSize: pageSize,
      search: "",
      filters: {},
      sort: [],
      skipTotal: skipTotal,
    };
    let result = await actions.messages.loadMessages(payload);
    result.list.forEach((message) => {
      if (message.alertType === 1) {
        convertVoice(message.messageBody, message.id);
      }
    });

    updateState({
      totalCount: result.totalCount,
      scheduledCount: result.scheduledCount,
      messageList: result.list,
      latestPage: result.list,
    });
    return result;
  }
  function onPageChanged(list) {
    setCheckboxState({});
    updateState({ currentPage: list });
  }
  const formatDescription = (string) => {
    return string.split(" ").slice(0, 10).join(" ");
  };
  const formattedDate = (orgDate) => {
    const originalDate = new Date(orgDate);

    const yesterday = () => {
      let d = new Date();
      d.setDate(d.getDate() - 1);
      return d;
    };
    const currentDate = new Date().toISOString().split("T")[0];
    const yesterdayDate = yesterday().toISOString().split("T")[0];
    const scheduledDate = orgDate.split("T")[0];
    const month = originalDate.getMonth() + 1;
    const date = originalDate.getDate();
    const year = originalDate.getFullYear();

    const formattedTime = originalDate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    if (scheduledDate === currentDate) {
      return `${formattedTime}`;
    } else if (scheduledDate === yesterdayDate) {
      return "Yesterday";
    } else {
      return `${month}/${date}/${year}`;
    }
  };
  const alertTypeName = (alertTypeValue) => {
    return state.alertType.find(
      (alertType) => alertType.value === alertTypeValue
    ).label;
  };
  const alertIcon = (alertTypeValue) => {
    if (alertTypeValue === 1) {
      return "fa fa-microphone";
    } else if (alertTypeValue === 2) {
      return "fa fa-envelope";
    } else {
      return "fa fa-mobile";
    }
  };

  async function convertVoice(messageBody, messageId) {
    try {
      const response = await convertTTS(messageBody);
      if (response.success) {
        setAudioUrls((prev) => ({
          ...prev,
          [messageId]: response.fileUrl,
        }));
      } else {
        console.error("TTS conversion failed:", response.error);
      }
    } catch (error) {
      console.error("Error during TTS conversion:", error);
    }
  }
  async function handleDelete() {
    updateState({
      isLoading: true,
    });
    let response = await api.messages.bulkDelete(state.filteredCheckboxes);
    if (response.success) {
      showToast("success", response.message);
      updateState({
        gridReset: state.gridReset + 1,
        showDeleteModal: false,
        isLoading: false,
      });
    }
  }

  return (
    <>
      <div className="page-layout-message">
        <PageLayout
          title="Messages"
          creditAlert={state}
          buttonBar={
            <div className="mobile-title">
              {isMobile && (
                <>
                  <div
                    className={`mobile-title-icon-div ${isOpen && "clicked"}`}
                  >
                    <div
                      className="mobile-title-progress-icon"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <i className="fa fa-bars-progress "></i>
                    </div>
                  </div>
                  {isOpen && (
                    <div className="dashboard-mobile-tooltip">
                      <Tooltip
                        placement="bottom"
                        className="gui-tooltip"
                        transition={false}
                        animation={false}
                      >
                        {state && (
                          <div className="progress-container">
                            <div className="progress-container-text">
                              {state.remainingCredits} Texts/Voice Messages Left
                              This{" "}
                              {state.billingCycle === "Monthly"
                                ? "Month"
                                : "Year"}
                            </div>
                            <progress
                              value={state.remainingCredits}
                              max={state.totalCredits}
                              className="styled-progress-bar"
                            ></progress>
                          </div>
                        )}
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
              <MessageDropdown />
            </div>
          }
        >
          <Card className="messages-card">
            {state.isLoading && <CircularProgress />}
            {!state.isLoading && (
              <>
                {isMobile ? (
                  <>
                    <div className="modal-footer-hr-divider"></div>
                    <div className="messages-search-header">
                      <div className="messages-search-header-tabs">
                        <MessageTabs
                          tabs={tabs}
                          selectedTab={selectedTab}
                          onTabChange={handleTabChange}
                        />
                      </div>
                      <div className="messages-search-header-filter">
                        <SliderSearchBox />
                        <div>
                          <GuiButton className="btn-outline btn-actions">
                            Actions
                          </GuiButton>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-hr-divider"></div>

                    <div className="messages-grid">
                      <Grid
                        itemTemplate={(g) => (
                          <div className="row">
                            <div className="check">
                              <div className="check-row">
                                <div className="check-title">
                                  <img
                                    src={g.profilePictureUrl}
                                    alt="Profile"
                                    className="profile-image"
                                  />
                                </div>
                                <div className="check-body">
                                  <div className="check-body-name ">
                                    <div className="check-body-name-header">
                                      {g.status === 2 && (
                                        <GuiButton className="mobile-scheduled-alert-type-btn">
                                          <i className="fa fa-clock "></i>
                                        </GuiButton>
                                      )}
                                      {g.status === 3 && (
                                        <GuiButton className="mobile-draft-alert-type-btn">
                                          <i className="fa fa-pen"></i>
                                        </GuiButton>
                                      )}
                                      {g.messageSendGroupName}

                                      <GuiButton className="alert-type-btn-mobile">
                                        <span>
                                          <i
                                            className={alertIcon(g.alertType)}
                                          ></i>
                                          {alertTypeName(g.alertType)}
                                        </span>
                                      </GuiButton>
                                    </div>
                                    <div className="check-body-actions">
                                      <span className="check-body-date">
                                        {formattedDate(g.scheduled)}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="check-body-details-mobile">
                                    <div className="check-body-details-mobile-header">
                                      <span className="msg-subject">
                                        {g.messageSubject}
                                      </span>
                                      {g.alertType === 1 ? (
                                        audioUrls[g.id] ? (
                                          <AudioPlayer
                                            src={audioUrls[g.id]}
                                            autoPlay={false}
                                          />
                                        ) : (
                                          <CircularProgress />
                                        )
                                      ) : (
                                        <span>
                                          {removeTags(
                                            formatDescription(g.messageBody)
                                          )}
                                          {" ..."}
                                        </span>
                                      )}
                                    </div>

                                    <i className="fa fa-ellipsis-vertical mobile-action-icon"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        mobileItemHeight={65}
                        reset={state.gridReset}
                        pageSize={20}
                        onItemsNeeded={loadRows}
                        onPageChanged={onPageChanged}
                      ></Grid>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="messages-search-header">
                      <SliderSearchBox />

                      <div className="messages-search-header-tabs">
                        <MessageTabs
                          tabs={tabs}
                          selectedTab={selectedTab}
                          onTabChange={handleTabChange}
                        />
                      </div>
                    </div>
                    <div className="modal-footer-hr-divider"></div>
                    <div className="messages-bulk-actions-bar">
                      <div className="messages-bulk-actions-bar-selected">
                        {state.selectedMsg} Selected
                      </div>
                      <div className="vr-divider">
                        <GuiButton
                          className="bulk-delete-btn"
                          disabled={!state.isDeleteEnabled}
                          onClick={(e) =>
                            updateState({
                              showDeleteModal: true,
                            })
                          }
                        >
                          <i className="fa fa-trash"></i> Delete
                        </GuiButton>
                      </div>
                    </div>
                    <div className="modal-footer-hr-divider"></div>
                    <div className="messages-grid">
                      <Grid
                        itemTemplate={(g) => (
                          <div className="row">
                            <div className="check">
                              <div className="check-row">
                                <div className="check-title">
                                  <GuiCheckbox
                                    name={`${g.groupId}_${g.id}`}
                                    value={
                                      checkboxState[`${g.groupId}_${g.id}`] ||
                                      false
                                    }
                                    onChange={updateCheckboxState}
                                    onClick={(e) => e.stopPropagation()}
                                  />

                                  <img
                                    src={g.profilePictureUrl}
                                    alt="Profile"
                                    className="profile-image"
                                  />
                                </div>
                                <div className="check-body">
                                  <div className="check-body-name">
                                    {g.messageSendGroupName}
                                  </div>
                                  <div className="check-body-details">
                                    {g.status === 2 && (
                                      <GuiButton className="scheduled-alert-type-btn">
                                        <span>
                                          <i className="fa fa-clock"></i>
                                          Scheduled
                                        </span>
                                      </GuiButton>
                                    )}
                                    {g.status === 3 && (
                                      <GuiButton className="draft-alert-type-btn">
                                        <span>
                                          <i className="fa fa-pen"></i>
                                          Draft
                                        </span>
                                      </GuiButton>
                                    )}

                                    <GuiButton className="alert-type-btn">
                                      <span>
                                        <i
                                          className={alertIcon(g.alertType)}
                                        ></i>
                                        {alertTypeName(g.alertType)}
                                      </span>
                                    </GuiButton>
                                    <span className="msg-subject">
                                      {g.messageSubject}
                                    </span>
                                    {g.alertType === 1 ? (
                                      audioUrls[g.id] ? (
                                        <AudioPlayer
                                          src={audioUrls[g.id]}
                                          autoPlay={false}
                                        />
                                      ) : (
                                        <CircularProgress />
                                      )
                                    ) : (
                                      <span>
                                        {removeTags(
                                          formatDescription(g.messageBody)
                                        )}
                                        {" ..."}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="check-body-actions">
                                  <span className="check-body-date">
                                    {formattedDate(g.scheduled)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        mobileItemHeight={65}
                        reset={state.gridReset}
                        pageSize={20}
                        onItemsNeeded={loadRows}
                        onPageChanged={onPageChanged}
                      ></Grid>
                    </div>
                  </>
                )}
              </>
            )}
          </Card>
        </PageLayout>

        {!useIsDesktop() && <BottomNavBar />}

        {state.showDeleteModal && !state.isLoading && (
          <Modal className="modal bulk-delete-modal">
            <div className="bulk-delete-modal-header">
              <span>Delete Message(s)?</span>
              <div
                onClick={(e) =>
                  updateState({
                    showDeleteModal: false,
                  })
                }
              >
                <i className="fa fa-close close-primary-icon cursor-pointer"></i>
              </div>
            </div>
            <div className="modal-footer-hr-divider"></div>
            <div className="bulk-delete-modal-content ">
              <span>
                This action cannot be undone. Please note only Scheduled and
                Draft messages can be deleted.
              </span>
            </div>
            <div className="bulk-delete-modal-footer">
              <GuiButton
                className="btn-outline"
                onClick={(e) =>
                  updateState({
                    showDeleteModal: false,
                  })
                }
              >
                Cancel
              </GuiButton>
              <GuiButton className="warning" onClick={handleDelete}>
                Delete Message(s)
              </GuiButton>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
