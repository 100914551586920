import React, { useRef, useState } from "react";
import { useEffect } from "react";
import LoadingSpinner from "./loading-spinner";

export function Scroller({
  onItemsNeeded,
  reset,
  itemHeight,
  itemTemplate,
  loadedItemsRef,
}) {
  let [state, setState] = useState({
    pageSize: Math.ceil((window.innerHeight / itemHeight) * 2),
  });
  let divRef = useRef();
  let sync = useRef({}).current;
  let pageSize = state.pageSize;

  useEffect(reload, [reset]);

  function reload() {
    sync.pages = {};
    if (loadedItemsRef) loadedItemsRef.current = [];

    sync.reloading = true;
    setState({ ...state });
    loadPage(1, false);
  }

  function loadPage(page, skipTotal) {
    if (sync.loading) return;

    sync.loading = true;
    onItemsNeeded(page, pageSize, skipTotal).then((result) => {
      sync.loading = false;
      sync.reloading = false;

      sync.pages[page] = result.list;
      sync.totalCount = skipTotal ? sync.totalCount : result.totalCount;
      if (loadedItemsRef) {
        Array.prototype.push.apply(loadedItemsRef.current, result.list);
      }

      onScroll();
    });
  }

  function onScroll() {
    let scrollTop = divRef.current.scrollTop;
    let height = divRef.current.offsetHeight;

    let topPage = Math.ceil(scrollTop / itemHeight / pageSize);
    let bottomPage = Math.ceil((scrollTop + height) / itemHeight / pageSize);
    topPage = Math.max(1, topPage);
    bottomPage = Math.max(1, bottomPage);

    // Total height
    let scrollHeight = (sync.totalCount || 0) * itemHeight;

    // Padding at top
    let offset = 0;
    for (let num = 1; num < topPage; num++) {
      offset +=
        (sync.pages[num] ? sync.pages[num].length : pageSize) * itemHeight; // Pages might have uneven sizes (ex "All Users" adds an item to the first page)
    }

    // Visible items
    let list = [];
    for (let num = topPage; num <= bottomPage; num++) {
      if (!sync.pages[num]) {
        loadPage(num, true);
        break;
      }

      list = list.concat(sync.pages[num]);
    }

    setState({
      ...state,
      list: list,
      scrollHeight: scrollHeight + "px",
      scrollOffset: offset + "px",
    });
  }

  let scrollStyle = {
    height: state.scrollHeight,
    paddingTop: state.scrollOffset,
  };

  return (
    <div className="scroll-container" onScroll={onScroll} ref={divRef}>
      <div className="list" style={scrollStyle}>
        {state.list && state.list.map((g) => itemTemplate(g))}

        <LoadingSpinner show={sync.reloading} />
      </div>
    </div>
  );
}
