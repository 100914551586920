import { useSelector } from "react-redux";
import actions from "../actions/actions";
import { GuiTextbox } from "../components/global-ui/gui-textbox";
import "../styles/edit-profile.scss";
import { useUpdateState } from "../utilities/utilities";
import { GuiButton } from "../components/global-ui/gui-button";
import user from "../actions/user-actions";
import { useEffect, useState } from "react";
import CircularProgress from "../actions/CircularProgress";
import { showToast } from "../components/toast";
import { UserRoleName } from "../utilities/constants";
import { GuiSelect } from "../components/global-ui/gui-select";
import { FileUploadButton } from "../components/image-library-modal/file-upload";
import api from "../api/api";
import { appState } from "../redux/redux-config";
import CropperModal from "./CropperModal";

export default function EditProfile({
  displayModal,
  onCloseClick,
  onSucessfullAdd,
}) {
  let [state, updateState] = useUpdateState({
    firstName: "",
    lastName: "",
    userType: "",
    loading: true,
    primaryEmail: "",
    isUpdateDisabled: false,
    userTypeName: "",
    profilePhotoURL: "",
    fileUrl: "",
    userTypeOptions: [],
    currentUserType: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    getUserDetails();
  }, []);

  async function getUserDetails() {
    let state = appState().user;
    updateState({ loading: true });
    await user.getSpecificUserDetails().then(async (result) => {
      let response = await api.user.getUserTypes(state.token);

      if (result.success && response.success) {
        const userTypeName = Object.keys(UserRoleName).find(
          (role) => UserRoleName[role] === result.userType
        );

        updateState({
          firstName: result.firstName,
          lastName: result.lastName,
          userType: result.userType,
          currentUserType: result.userType,
          primaryEmail: result.emailAddress,
          userTypeOptions: response.list.map((g) => ({
            value: g.id,
            label: g.name,
          })),
          userTypeName: userTypeName,
          loading: false,
          profilePhotoURL: result.profilePhotoURL,
        });
      }
    });
  }
  const handleChange = (name, value) => {
    updateState(name, value);
  };
  async function onUpdateClick() {
    updateState({ loading: true, isUpdateDisabled: true });

    const payload = {
      firstName: state.firstName,
      lastName: state.lastName,
      emailAddress: state.primaryEmail,
      UserTypeId: state.userType,
    };
    let response = await user.updateUserProfile(payload);
    if (response.success) {
      if (state.fileUrl !== "") {
        await user.uploadProfilePhoto(state.fileUrl);
      }
      updateState({ loading: false });
      onCloseClick();
      onSucessfullAdd();
      showToast("success", "Contact Updated");
    }
  }
  async function handlefileChange(file) {
    updateState({ fileUrl: file });
    let imagePreview = URL.createObjectURL(file);
    updateState({ imgSrc: imagePreview });
    setModalOpen(true);
  }
  return (
    <>
      <div className={`edit-profile Modal ${displayModal ? "Show" : "hide"}`}>
        {state.loading ? (
          <CircularProgress />
        ) : (
          <>
            <div className="edit-profile-header">
              <h4>Edit Profile</h4>
              <span onClick={onCloseClick}>
                <i className="fa fa-close close-primary-icon cursor-pointer"></i>
              </span>
            </div>
            <div className="edit-profile-body">
              <div>
                <span className="edit-profile-body-title">Profile Photo</span>
                <div className="edit-profile-body-image">
                  <div className="background-curve"></div>
                  <div className="profile-image-container">
                    <div className="edit-icon">
                      <FileUploadButton
                        name="logoUrl"
                        isUploadIcon="true"
                        onChange={(file) => handlefileChange(file)}
                        acceptedTypes=".jpg, .jpeg, .png"
                        maxFileSizeMB={1}
                      />
                    </div>
                    <CropperModal
                      modalOpen={modalOpen}
                      src={state.imgSrc}
                      setPreview={(item) => updateState({ imgSrc: item })}
                      setModalOpen={setModalOpen}
                      profilePhotoURL={state.profilePhotoURL}
                      setFormData={(file) => updateState({ fileUrl: file })}
                    />
                    {!state.imgSrc && !state.profilePhotoURL ? (
                      <div className="initials">
                        {(state.firstName + " " + state.lastName)
                          .split(" ")
                          .map((n) => n[0])
                          .join("")}
                      </div>
                    ) : (
                      <img
                        src={
                          state.imgSrc && !modalOpen
                            ? state.imgSrc
                            : state.profilePhotoURL
                        }
                        alt="Profile"
                        className="profile-image"
                      />
                    )}
                  </div>
                </div>
                <div className="edit-profile-body-textbox">
                  <GuiTextbox
                    label="First Name"
                    name="firstName"
                    value={state.firstName}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </div>
                <div className="edit-profile-body-textbox">
                  <GuiTextbox
                    label="Last Name"
                    name="lastName"
                    value={state.lastName}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </div>
                <div className="edit-profile-body-select">
                  {state.currentUserType === 4 ? (
                    <GuiSelect
                      label="User Type"
                      name="userType"
                      value={state.userType}
                      options={state.userTypeOptions}
                      onChange={(name, option) =>
                        handleChange(name, option.value)
                      }
                    />
                  ) : (
                    <div>
                      <span className="user-type-name">User Type : </span>
                      <GuiButton
                        className={`role-badge${
                          state.userType === 1 ? "-student" : "-faculty"
                        }`}
                      >
                        {state.userTypeName}
                      </GuiButton>
                    </div>
                  )}
                </div>
                <h4>Primary Contact</h4>
                <div className="edit-profile-body-textbox">
                  <GuiTextbox
                    label="Primary Email"
                    name="primaryEmail"
                    value={state.primaryEmail}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </div>
              </div>
            </div>
            <div className="hr-divider"></div>

            <div className="edit-profile-btns">
              <GuiButton className="cancel-btn" onClick={onCloseClick}>
                Cancel
              </GuiButton>
              <GuiButton
                className="update-btn"
                disabled={state.isUpdateDisabled}
                onClick={onUpdateClick}
              >
                Update
              </GuiButton>
            </div>
          </>
        )}
      </div>
    </>
  );
}
