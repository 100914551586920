import "../styles/side-bar.scss";
import React, { useState } from "react";
import actions from "../actions/actions";
import { AlertStatus, Path } from "../utilities/constants";
import { useSelector } from "react-redux";
import { Blackout } from "./modal";
import navigate from "../utilities/navigation";
import { useEffect } from "react";
import Resizer from "./resizer";

export default function SideNavigation() {
  let { sidebarExpanded, isMobile } = useSelector((store) => store.page);
  let [expandedItem, setExpandedItem] = useState();
  let path = useSelector((store) => store.router.location.pathname);

  useEffect(() => {
    // When the user navigates, reset which menu item is expanded
    if (
      path.startsWith(Path.Send) ||
      path.startsWith(Path.Track) ||
      path === Path.Archived ||
      path === Path.SendQuickLaunch ||
      path === Path.Templates
    )
      setExpandedItem(Path.Send);
  }, [path]);

  function toggleExpanded(e, itemLabel) {
    e.stopPropagation();
    setExpandedItem(expandedItem === itemLabel ? null : itemLabel);
  }

  function goTo(e, path, hasChildren) {
    e.stopPropagation(); // Keep parent menu item from responding to the same click

    if (isMobile && hasChildren) {
      setExpandedItem(path);
      return;
    }

    if (path === Path.Send) actions.alerts.showCreateAlert(false);
    else if (path === Path.SendEmergency) actions.alerts.showCreateAlert(true);
    else if (path === Path.Track)
      actions.track.navigateToTrack(AlertStatus.All);
    else if (path === Path.TrackSent)
      actions.track.navigateToTrack(AlertStatus.Sent);
    else if (path === Path.TrackScheduled)
      actions.track.navigateToTrack(AlertStatus.Scheduled);
    else if (path === Path.TrackDrafts)
      actions.track.navigateToTrack(AlertStatus.Draft);
    else navigate(path);

    if (isMobile) actions.page.toggleSideBar(false);
    return false;
  }

  return (
    <>
      {isMobile && (
        <Blackout
          show={sidebarExpanded}
          onClick={() => actions.page.toggleSideBar(false)}
        />
      )}

      <div className={"side-bar" + (sidebarExpanded ? "" : " collapsed")}>
        <SideBarItem
          text="My Dashboard"
          icon="fa fa-address-card"
          onClick={(e) => goTo(e, Path.Dashboard)}
          selected={path?.startsWith(Path.Dashboard)}
          dataQa="dashboard"
        ></SideBarItem>
        <SideBarItem
          text="Messages"
          icon="fas fa-paper-plane"
          onClick={(e) => goTo(e, Path.Messages)}
          selected={path === Path.Messages}
          dataQa="messages"
        ></SideBarItem>
        <SideBarItem
          text="Alerts"
          icon="fas fa-bullhorn"
          onClick={(e) => goTo(e, Path.Send, true)}
          expanded={sidebarExpanded && expandedItem === Path.Send}
          toggleExpanded={(e) => toggleExpanded(e, Path.Send)}
          dataQa="send"
        >
          {!sidebarExpanded && <SideBarItem text="Alerts" />}
          <SideBarItem
            text="All"
            onClick={(e) => goTo(e, Path.Track)}
            selected={path === Path.Track}
            dataQa="all"
          />
          <SideBarItem
            text="Sent"
            onClick={(e) => goTo(e, Path.TrackSent)}
            selected={path === Path.TrackSent}
            dataQa="sent"
          />
          <SideBarItem
            text="Scheduled"
            onClick={(e) => goTo(e, Path.TrackScheduled)}
            selected={path === Path.TrackScheduled}
            dataQa="scheduled"
          />
          <SideBarItem
            text="Draft"
            onClick={(e) => goTo(e, Path.TrackDrafts)}
            selected={path === Path.TrackDrafts}
            dataQa="draft"
          />
          <SideBarItem
            text="Archived"
            onClick={(e) => goTo(e, Path.Archived)}
            selected={path === Path.Archived}
            dataQa="archived"
          />
          <SideBarItem
            text="Alert Quick Launch"
            onClick={(e) => goTo(e, Path.SendQuickLaunch)}
            selected={path === Path.SendQuickLaunch}
            dataQa="quick-alert"
          />
          {/*                    <SideBarItem text="Templates" onClick={e => goTo(e, Path.Templates)} selected={path === Path.Templates} dataQa="templates" />*/}
        </SideBarItem>

        {/*<SideBarItem text="Calendar" icon="fas fa-calendar" onClick={e => goTo(e, Path.Calendar)} selected={path === Path.Calendar} dataQa="calendar"></SideBarItem>*/}
        {/*<SideBarItem text="Keywords" icon="fas fa-key" onClick={e => goTo(e, Path.Keywords)} selected={path === Path.Keywords} dataQa="keywords"></SideBarItem>*/}
        <SideBarItem
          text="People"
          icon="fas fa-user"
          onClick={(e) => goTo(e, Path.People)}
          selected={path?.startsWith(Path.People)}
          dataQa="people"
        ></SideBarItem>
        <SideBarItem
          text="Groups"
          icon="fas fa-users"
          onClick={(e) => goTo(e, Path.Groups)}
          selected={path === Path.Groups}
          dataQa="groups"
        ></SideBarItem>

        {/*<SideBarItem text="Admin Reports" icon="fas fa-file-chart-line" onClick={e => goTo(e, Path.ReportCommuication)} expanded={sidebarExpanded && expandedItem === Path.ReportCommuication} toggleExpanded={e => toggleExpanded(e, Path.ReportCommuication)} dataQa="reports">*/}
        {/*    {!sidebarExpanded && <SideBarItem text="Admin Reports" />}*/}
        {/*    <SideBarItem text="Communication Report" onClick={e => goTo(e, Path.ReportCommuication)} selected={path === Path.ReportCommuication} dataQa="reports-communication" />*/}
        {/*    <SideBarItem text="Recent Activity" onClick={e => goTo(e, Path.ReportRecent)} selected={path === Path.ReportRecent} dataQa="reports-recent" />*/}
        {/*    <SideBarItem text="Invalid Contacts" onClick={e => goTo(e, Path.ReportInvalidContacts)} selected={path === Path.ReportInvalidContacts} dataQa="reports-invalid-contacts" />*/}
        {/*    <SideBarItem text="Opt Out Report" onClick={e => goTo(e, Path.ReportOptOut)} selected={path === Path.ReportOptOut} dataQa="reports-optout" />*/}
        {/*    <SideBarItem text="User Location" onClick={e => goTo(e, Path.ReportLocation)} selected={path === Path.ReportLocation} dataQa="reports-location" />*/}
        {/*</SideBarItem>*/}

        {/*Only these reports below have been created.*/}
        <SideBarItem
          text="Admin Reports"
          icon="fas fa-file-chart-line"
          onClick={(e) => goTo(e, Path.ReportCommuication)}
          expanded={sidebarExpanded && expandedItem === Path.ReportCommuication}
          toggleExpanded={(e) => toggleExpanded(e, Path.ReportCommuication)}
          dataQa="reports"
        >
          {!sidebarExpanded && <SideBarItem text="Admin Reports" />}
          <SideBarItem
            text="Communication Report"
            onClick={(e) => goTo(e, Path.ReportCommuication)}
            selected={path === Path.ReportCommuication}
            dataQa="reports-communication"
          />
          <SideBarItem
            text="Recent Activity"
            onClick={(e) => goTo(e, Path.ReportRecent)}
            selected={path === Path.ReportRecent}
            dataQa="reports-recent"
          />
          <SideBarItem
            text="Invalid Contacts"
            onClick={(e) => goTo(e, Path.ReportInvalidContacts)}
            selected={path === Path.ReportInvalidContacts}
            dataQa="reports-invalid-contacts"
          />
          <SideBarItem
            text="Opt Out Report"
            onClick={(e) => goTo(e, Path.ReportOptOut)}
            selected={path === Path.ReportOptOut}
            dataQa="reports-optout"
          />
        </SideBarItem>

        <SideBarItem
          className="collapse-button"
          icon="far fa-chevron-circle-left"
          text="Collapse"
          onClick={() => actions.page.toggleSideBar(!sidebarExpanded)}
        />
      </div>
    </>
  );
}

function SideBarItem({
  icon,
  text,
  className,
  expanded,
  toggleExpanded,
  selected,
  children,
  onClick,
  dataQa,
}) {
  return (
    <div className={"side-bar-item-container " + (className || "")}>
      <div
        className={"side-bar-item" + (selected ? " selected" : "")}
        onClick={onClick}
        data-qa={dataQa}
      >
        <i className={"icon " + (icon || "")}></i>
        <span>{text}</span>
        {children && (
          <i
            className={"fal fa-chevron-down" + (expanded ? " expanded" : "")}
            onClick={toggleExpanded}
          />
        )}
      </div>
      {children && (
        <Resizer className="side-bar-sub-items" expanded={expanded}>
          {children}
        </Resizer>
      )}
    </div>
  );
}
