import alerts from "./alert-actions";
import contacts from "./contact-actions";
import files from "./file-actions";
import groups from "./group-actions";
import track from "./track-actions";
import page from "./page-actions";
import people from "./people-actions";
import reports from "./report-actions";
import user from "./user-actions";
import admin from "./admin-actions";
import messages from "./message-actions";

const actions = {
  alerts: alerts,
  contacts: contacts,
  files: files,
  groups: groups,
  track: track,
  page: page,
  people: people,
  admin: admin,
  reports: reports,
  user: user,
  messages: messages,
};
export default actions;
