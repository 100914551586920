import React from "react";
import PropTypes from "prop-types";
import "../../styles/message-tabs.scss";

function MessageTabs({ tabs, selectedTab, onTabChange }) {
  return (
    <div className="tabs-container">
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className={`tab-item ${selectedTab === tab.id ? "active" : ""}`}
          onClick={() => onTabChange(tab.id)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
}

MessageTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default MessageTabs;
