import "../styles/slider-search-box.scss";
import React, { useEffect, useRef, useState } from "react";
import { GuiSelect } from "./global-ui/gui-select";
import { useUpdateState } from "../utilities/utilities";
import { GuiTextbox } from "./global-ui/gui-textbox";
import { getStartOfMonth } from "./global-ui/gui-date";
import { GuiButton } from "./global-ui/gui-button";

export function SliderSearchBox({ onSearchChange, searching, minLength }) {
  let sync = useRef({}).current;
  const [isOpen, setIsOpen] = useState(false);
  let [state, updateState] = useUpdateState(() => {
    return {
      messageType: 0,
      startDate: getStartOfMonth(),
      endDate: new Date(),
      typeOptions: [
        { value: 0, label: "All" },
        { value: 1, label: "Voice" },
        { value: 2, label: "Email" },
        { value: 3, label: "SMS" },
      ],
    };
  });
  const onMessageTypeChange = (name, value) => {
    updateState({ [name]: value });
  };

  useEffect(() => {
    if (!searching && sync.text !== sync.lastSearch) doSearch(sync.text);
  }, [searching]);

  function onChange(e) {
    let text = e.currentTarget.value;
    let shouldSearch =
      !searching &&
      (!minLength ||
        text.length >= minLength ||
        sync.text?.length >= minLength); // Must be at least minLength or have a previous search

    sync.text = text;
    if (shouldSearch) doSearch(sync.text);
  }

  function doSearch(text) {
    sync.lastSearch = text;
    onSearchChange(text);
  }

  return (
    <div className="slider-search-container">
      <div className="slider-search-box">
        <i className="fal fa-search search-icon"></i>
        <input
          name="search"
          placeholder="Search"
          onChange={onChange}
          data-qa="search"
        />
        <i
          className="fa fa-sliders filter-icon"
          onClick={() => setIsOpen(!isOpen)}
        ></i>
      </div>
      <div className="slider-dropdown">
        {isOpen && (
          <div className="slider-dropdown-menu">
            <div className="slider-dropdown-menu-item">
              <span className="slider-dropdown-menu-item-header">Filters</span>
            </div>
            <div className="slider-dropdown-menu-item">
              <GuiSelect
                label="Message Type"
                name="messageType"
                value={state.messageType}
                onChange={(name, option) =>
                  onMessageTypeChange(name, option.value)
                }
                options={state.typeOptions}
              />
            </div>
            <div className="slider-dropdown-menu-item">
              <GuiTextbox name="from" label="From"></GuiTextbox>
            </div>
            <div className="slider-dropdown-menu-item">
              <GuiTextbox name="to" label="To"></GuiTextbox>
            </div>
            <div className="slider-dropdown-menu-item">
              <GuiTextbox
                name="includesGroup"
                label="Includes Group"
              ></GuiTextbox>
            </div>
            <div className="slider-dropdown-menu-btns">
              <GuiButton className="clear-btn">Clear</GuiButton>
              <GuiButton disabled={true}>Search</GuiButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
