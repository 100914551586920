import { useRef, useState } from "react";
import "../styles/CropperModal.scss";
import AvatarEditor from "react-avatar-editor";
import { GuiButton } from "../components/global-ui/gui-button";
import { FileUploadButton } from "../components/image-library-modal/file-upload";
const CropperModal = ({
  src,
  modalOpen,
  setModalOpen,
  setPreview,
  setFormData,
  profilePhotoURL,
}) => {
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);
  const handleSave = async () => {
    if (cropRef) {
      // Get the cropped image as a Data URL
      const dataUrl = cropRef.current.getImage().toDataURL();

      // Convert Data URL to Blob
      const response = await fetch(dataUrl);
      const blob = await response.blob();

      // Create a file object from the blob
      const file = new File([blob], "cropped-image.png", { type: blob.type });
      setPreview(URL.createObjectURL(blob));
      setFormData(file);
      setModalOpen(false);
    }
  };
  async function handlefileChange(file) {
    setFormData(file);
    setPreview(URL.createObjectURL(file));
  }
  return (
    modalOpen && (
      <div className="cropper-modal">
        <div className="cropper-modal-content">
          <div className="cropper-modal-header">
            <span>Edit Photo</span>
            <i
              className="fa fa-close cursor-pointer"
              onClick={() => {
                setModalOpen(false);
                setPreview(profilePhotoURL);
              }}
            ></i>
          </div>
          <div className="cropper-modal-body">
            <div className="cropper-avatar-editor">
              {/* AvatarEditor */}
              <AvatarEditor
                ref={cropRef}
                image={src}
                style={{ width: "100%", height: "100%" }}
                border={10}
                borderRadius={100}
                color={[0, 0, 0, 0.72]}
                scale={slideValue / 10}
                rotate={0}
              />
            </div>

            {/* Custom Slider */}

            <div className="cropper-slider-container">
              <i className="fa fa-minus"></i>
              <input
                type="range"
                min="10"
                max="50"
                value={slideValue}
                onChange={(e) => setSlideValue(e.target.value)}
                className="custom-slider"
              />
              <i className="fa fa-plus"></i>
            </div>
          </div>
          <div className="cropper-button-container">
            <GuiButton
              className="delete-btn"
              onClick={() => {
                setModalOpen(false);
                setPreview(profilePhotoURL);
              }}
            >
              Cancel
            </GuiButton>
            <FileUploadButton
              name="logoUrl"
              onChange={(file) => handlefileChange(file)}
              acceptedTypes=".jpg, .jpeg, .png"
              maxFileSizeMB={1}
              isUploadBtn="true"
            />

            <GuiButton className="save-btn" onClick={handleSave}>
              Save
            </GuiButton>
          </div>
        </div>
      </div>
    )
  );
};

export default CropperModal;
