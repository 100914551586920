import React, { useEffect, useState } from "react";
import "../styles/group-membership.scss";
import { SettingsApi } from "../actions/settings-action";
import { useOnFirstLoad } from "../utilities/hooks";
import CircularProgress from "../actions/CircularProgress";
import navigate from "../utilities/navigation";
import { Path } from "../utilities/constants";

export default function GroupMembership({
  isEditing,
  isComponentDisabled,
  openGroupPreview,
}) {
  const [myGroupDetails, setMyGroupDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loaderError, setError] = useState(false);

  useOnFirstLoad(() => {
    getMyGroups();
  });
  useEffect(() => {
    let timeoutId;

    if (loading) {
      // Set a timeout for 15 seconds
      timeoutId = setTimeout(() => {
        setLoading(false);
        setError(true);
      }, 15000);
    }

    // Cleanup the timeout on component unmount or when loading changes
    return () => clearTimeout(timeoutId);
  }, [loading]);

  function getMyGroups() {
    setError(false);
    try {
      SettingsApi.getUserGroups().then((result) => {
        setMyGroupDetails(result.groups);
        if (result.success) {
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      });
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }
  const onMsgClick = (item) => {
    openGroupPreview(item);
  };
  return (
    <div
      className={`my-groups-container ${loaderError && "loader-error"} ${
        (isComponentDisabled || isEditing) && "disabled"
      }`}
    >
      {loading && <CircularProgress />}
      {!loading && loaderError && (
        <span>Failed to load data, please try again later.</span>
      )}
      {!loading && !loaderError && (
        <>
          <div className="header">
            <span className="header-title">My Groups</span>
          </div>

          <div className="groups-list">
            {myGroupDetails.slice(0, 5).map((item, index) => (
              <>
                <div
                  className="flex-wrap-row cursor-pointer"
                  key={index}
                  onClick={() => onMsgClick(item)}
                >
                  <div className="avatar my-groups-column col-1">
                    {item.avatar ? (
                      <img src={item.avatar} alt={`${item.groupName}`} />
                    ) : (
                      <div className="initials">
                        {item.groupName
                          .split(" ")
                          .map((n) => n[0])
                          .slice(0, 2)
                          .join("")}
                      </div>
                    )}
                  </div>
                  {/* </div> */}
                  <div className="my-groups-column col-2 my-groups-group-name">
                    {item.groupName}
                  </div>
                  <div className="my-groups-column col-3 my-groups-group-members">
                    {item.userGroupCount} Members
                  </div>
                  <div className="my-groups-column col-4">
                    {item.description}
                  </div>
                </div>
                <div className="my-groups-divider"></div>
              </>
            ))}
          </div>
          <div
            className="go-to-groups-btn"
            onClick={() => navigate(Path.Groups)}
          >
            Go to Groups
            <i className=" go-to-groups-icon far fa-chevron-right"></i>
          </div>
        </>
      )}
    </div>
  );
}
