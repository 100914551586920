import api from "../api/api";
import { appState, dispatch } from "../redux/redux-config";

const messages = {
  loadMessages: async function (payload) {
    try {
      const result = await api.messages.list(payload);
      if (result.success) {
        dispatch({
          type: "MESSAGES_LOADED",
          payload: {
            list: result.alerts,
            totalCount: result.totalCount,
            scheduledCount: result.scheduledCount,
          },
        });
        return result;
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  },
};
export default messages;

const initialState = {
  //   sync: {}, // Tracks sync/loading states
  messages: [], // List of messages
  totalCount: 0, // Total number of messages
  scheduledCount: 0,
  selectedMessage: null, // Message selected for bulk actions
  filters: [], // Filter options
};

export function messageReducer(state = initialState, action) {
  if (state === undefined) return initialState;
  switch (action.type) {
    case "MESSAGES_LOADED":
      return {
        ...state,
        messages: action.payload.list,
        totalCount: action.payload.totalCount,
        scheduledCount: action.payload.scheduledCount,
      };

    default:
      return state;
  }
}
