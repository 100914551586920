import React, { useState } from "react";
import "../styles/rm-preview-popup.scss";
import { useSelector } from "react-redux";
import { GuiButton } from "../components/global-ui/gui-button";
import CircularProgress from "../actions/CircularProgress";
import { useOnFirstLoad } from "../utilities/hooks";
import actions from "../actions/actions";
import { routerHistory } from "../redux/redux-config";
const GroupMembershipPreviewPopup = ({
  groupPreviewModal,
  onClose,
  groupPreviewModalData,
}) => {
  let isMobile = useSelector((store) => store.page.isMobile);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  useOnFirstLoad(() => {
    if (groupPreviewModalData.users && groupPreviewModalData.users.length > 0) {
      setUsers(groupPreviewModalData.users);
      setLoading(false);
    }
  });
  async function groupDetails(id, groupTypeName) {
    let details = await actions.groups.getAllGroupMewmbers(id);
    routerHistory.push(`/group-members/${id}`, {
      groupDetails: details,
      groupName: groupTypeName,
    });
  }
  return (
    <div className={`Modal ${groupPreviewModal ? "Show" : "hide"}`}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="modal-header">
            {isMobile && (
              <span onClick={onClose} className="mr-2">
                <i className="fa fa-chevron-left close-primary-icon cursor-pointer"></i>
              </span>
            )}
            <h4>Group Preview</h4>
            {!isMobile && (
              <span onClick={onClose}>
                <i className="fa fa-close close-primary-icon cursor-pointer"></i>
              </span>
            )}
          </div>

          <div className="gm-modal-body">
            <div className="gm-modal-footer-hr-divider"></div>
            <div className="gm-modal-title">
              <div className="gm-modal-title-avatar">
                <div className="initials">
                  {groupPreviewModalData.groupName
                    .split(" ")
                    .map((n) => n[0])
                    .slice(0, 2)
                    .join("")}
                </div>
              </div>
              <div className="gm-modal-title-name-desc">
                <div className="gm-modal-title-name-group">
                  {groupPreviewModalData.groupName}
                </div>
                <div className="gm-modal-title-desc-group">
                  {groupPreviewModalData.description}
                </div>
              </div>
            </div>
            <div className="gm-modal-footer-hr-divider"></div>
            <div className="gm-modal-body-content">
              <div className="gm-modal-body-content-title">
                <div className="gm-modal-body-content-title-name">Members</div>
                <div className="gm-modal-body-content-title-number">
                  {groupPreviewModalData.users.length} members
                </div>
              </div>
              <div className="gm-modal-body-content-list">
                {users.map((data) => (
                  <>
                    <div
                      className="gm-modal-content-list-title"
                      key={data.userId}
                    >
                      <div className="gm-modal-title-avatar">
                        {data.profilePhotoURL ? (
                          <img
                            src={data.profilePhotoURL}
                            alt="Profile"
                            className="profile-image"
                          />
                        ) : (
                          <div className="initials">
                            {(data.firstName + " " + data.lastName)
                              .split(" ")
                              .map((n) => n[0])
                              .join("")}
                          </div>
                        )}
                      </div>
                      <div className="gm-modal-content-list-title-name">
                        {data.firstName} {data.lastName}
                      </div>
                    </div>
                    <div className="gm-modal-footer-hr-divider"></div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="rm-modal-footer">
            <div className="rm-modal-footer-hr-divider"></div>
            <div className="rm-modal-footer-btns">
              <GuiButton className="cancel-btn" onClick={onClose}>
                Close
              </GuiButton>

              <GuiButton
                className="add-btn"
                onClick={() =>
                  groupDetails(
                    groupPreviewModalData.groupId,
                    groupPreviewModalData.groupTypeName
                  )
                }
              >
                View Group Page
              </GuiButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupMembershipPreviewPopup;
