import React, { useEffect, useState } from "react";
import "../styles/BottomNavBar.scss";
import navigate from "../utilities/navigation";
const BottomNavBar = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const location = window.location;
  useEffect(() => {
    if (location.pathname === "/") {
      setActiveTab("dashboard");
    } else if (location.pathname === "/messages") {
      setActiveTab("messages");
    }
  }, [location.pathname]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "dashboard") {
      navigate("/");
    }
    if (tab === "messages") {
      navigate("/messages");
    }
    // placed placeholder for future reference.
    // if (tab === "people") {
    //   navigate("/people");
    // }
    // if (tab === "groups") {
    //   navigate("/groups");
    // }
  };

  return (
    <div className="bottom-nav-bar">
      <div
        className={`nav-item ${activeTab === "dashboard" ? "active" : ""}`}
        onClick={() => handleTabChange("dashboard")}
      >
        <i className="fa fa-home"></i>
        <span>My Dashboard</span>
      </div>
      <div
        className={`nav-item ${activeTab === "messages" ? "active" : ""}`}
        onClick={() => handleTabChange("messages")}
      >
        <i className="fa fa-paper-plane"></i>
        <span>Messages</span>
      </div>
      <div
        className={`nav-item ${activeTab === "people" ? "active" : ""}`}
        // onClick={() => handleTabChange("people")}
      >
        <i className="fa fa-user"></i>
        <span>People</span>
      </div>
      <div
        className={`nav-item ${activeTab === "groups" ? "active" : ""}`}
        // onClick={() => handleTabChange("groups")}
      >
        <i className="fa fa-users"></i>
        <span>Groups</span>
      </div>
    </div>
  );
};

export default BottomNavBar;
