import { apiRequestWithChecks } from "./api";

export const user = {
  getUserInfo: function (token) {
    return apiRequestWithChecks("GET", "/api/user", {
      Authorization: "Bearer " + token,
    });
  },

  getUserCount: function (token) {
    return apiRequestWithChecks("GET", "/api/user/count", {
      Authorization: "Bearer " + token,
    });
  },

  getUserTypes: function (token) {
    return apiRequestWithChecks("GET", "/api/user/types", {
      Authorization: "Bearer " + token,
    });
  },

  getUserProfile: function (token) {
    return apiRequestWithChecks("GET", "api/user/GetUserProfile", {
      Authorization: "Bearer " + token,
    });
  },
  getRecentMessages: function (token, alertType) {
    return apiRequestWithChecks(
      "GET",
      "api/dashboard/GetRecentMessages?alertType=" + alertType,
      {
        Authorization: "Bearer " + token,
      }
    );
  },
  getMessagePreview: function (token, alertId) {
    return apiRequestWithChecks(
      "GET",
      "api/dashboard/getPreviewAlertDetail?alertId=" + alertId,
      {
        Authorization: "Bearer " + token,
      }
    );
  },
  getSpecificUserDetails: function (token) {
    return apiRequestWithChecks(
      "GET",
      "api/user/GetSpecificUserProfileDetail",
      {
        Authorization: "Bearer " + token,
      }
    );
  },
  updateUserProfile: function (token, payload) {
    return apiRequestWithChecks(
      "PUT",
      "/api/user/update",
      {
        Authorization: "Bearer " + token,
      },
      payload
    );
  },
  updatePreference: function (token, payload) {
    return apiRequestWithChecks(
      "PUT",
      "/api/dashboard/updatePreference",
      {
        Authorization: "Bearer " + token,
      },
      payload
    );
  },

  getAlertCredit: function (token, payload) {
    return apiRequestWithChecks(
      "GET",
      "/api/alertCredit",
      { Authorization: "Bearer " + token },
      payload
    );
  },
  amplifyLogin: function (payload) {
    return apiRequestWithChecks(
      "POST",
      "/api/authentication/amplify_login",
      null,
      payload
    );
  },

  uploadProfilePhoto: function (token,file) {
    const formData = new FormData();
    formData.append('file', file); 
    return apiRequestWithChecks(
      "POST",
      "/api/user/UploadProfilePhoto",
      {
        Authorization: "Bearer " + token,
      },
      formData 
    );
  },
  ValidateUnitySession: function (sessionKey) {
    return apiRequestWithChecks(
      "POST",
      "/api/authentication/ValidateUnitySession?sessionKey=" + sessionKey
    );
  },
};
